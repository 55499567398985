import * as Lucide from "lucide-react";
import { Link, type LoaderFunctionArgs, Outlet, useLocation, useRouteLoaderData } from "react-router";
import { getUserSources } from "~/.server/source";
import { requireAppToken } from "~/.server/token";
import { Header } from "~/components/header";
import { cn, invariant } from "~/lib/utils";
import { privateSourceSlug } from "~/model/source";
import { getSourceMeta } from "~/sources/config";
import { sidebarItems } from "./items";

export async function loader({ request }: LoaderFunctionArgs) {
  const token = await requireAppToken(request);
  const sources = await getUserSources(token.userId);
  return { sources };
}

export function useSources() {
  const sources = useRouteLoaderData<typeof loader>("pages/settings/layout")?.sources;
  invariant(sources, "No sources");
  return sources;
}

export type SettingsSourceShape = Awaited<ReturnType<typeof loader>>["sources"][number];

const itemClass =
  "px-2 py-1.5 flex flex-row gap-2 rounded-md items-center hover:bg-foreground/5 focusable mr-4 hover:text-foreground";

const activeItemClass = cn(itemClass, "bg-foreground/5 hover:bg-foreground/5 text-foreground");

function SidebarSource({ source }: { source: SettingsSourceShape }) {
  const location = useLocation();
  const href = `/settings/sources${privateSourceSlug(source)}`;
  const meta = getSourceMeta(source.sourceType);
  const isActive = location.pathname === href;
  return (
    <li>
      <Link to={href} className={cn(itemClass, isActive && activeItemClass)}>
        <img src={meta.icon} alt={meta.name} className={cn("size-4", !source.enabled && "grayscale")} />
        <span className="truncate">{source.sourceName}</span>
      </Link>
    </li>
  );
}

function SidebarSources() {
  const sources = useSources();
  return (
    <ul className="mt-2 ml-6 flex flex-col gap-2">
      {sources.map((source) => (
        <SidebarSource key={source.sourceId} source={source} />
      ))}
    </ul>
  );
}

function SidebarItem({ name, Icon, href }: (typeof sidebarItems)[number]) {
  const location = useLocation();
  const isActive = location.pathname === href;
  const showSources = href === "/settings/sources";
  return (
    <li key={href}>
      <Link to={href} className={cn(itemClass, isActive && activeItemClass)}>
        <Icon className="size-4" />
        <span>{name}</span>
      </Link>
      {showSources && <SidebarSources />}
    </li>
  );
}

function Sidebar() {
  return (
    <ul className="flex flex-col gap-1 text-sm py-2 text-muted-foreground w-62 shrink-0 border-r border-divider pl-6">
      <li key="exit">
        <a href="/" className={cn(itemClass, "mb-2")}>
          <Lucide.ChevronLeft className="size-4" />
          <span>Back to app</span>
        </a>
      </li>
      {sidebarItems.map((item) => (
        <SidebarItem key={item.href} {...item} />
      ))}
    </ul>
  );
}

export default function SettingsLayout() {
  return (
    <div className="flex flex-col h-full w-full items-stretch">
      <Header />
      <div>
        <div className="flex flex-row max-w-3xl mx-auto py-14">
          <Sidebar />
          <main className="grow shrink">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}

export function SettingsContent({ className, children }: { className?: string; children: React.ReactNode }) {
  return <div className={cn("mx-6", className)}>{children}</div>;
}

export function SettingsHeader({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col space-y-1.5 pt-4">{children}</div>;
}

export function SettingTitle({ children }: { children: React.ReactNode }) {
  return (
    <div className="text-xl font-serif font-semibold leading-none tracking-tight uppercase flex items-center gap-2">
      {children}
    </div>
  );
}

export function SettingsDescription({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={cn("text-sm text-muted-foreground mt-2 flex flex-row items-start", className)}>{children}</div>
  );
}

export function SettingsBody({ className, children }: { className?: string; children: React.ReactNode }) {
  return <div className={cn("pb-6", className)}>{children}</div>;
}

export function SettingsRow({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div
      className={cn(
        "flex flex-row items-center justify-between border-t border-divider mt-6 pt-6 mb-4 gap-2",
        className,
      )}
    >
      {children}
    </div>
  );
}
